/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var actual = null, total = null;

(function ($, w) {

    $('a[data-role="change-video"]').on('click', function () {
        var link = 'https://www.youtube.com/embed/' + $(this).data('id');
        $('#iframeVideo').attr('src', link);
    });

    function switchVideo(hide, show) {
        $('.videoTestimonialModal[data-index="' + hide + '"]').modal('hide');
        $('.videoTestimonialModal[data-index="' + show + '"]').modal('show');
    }

    $('#menu-main-menu li').unbind('click');
    total = $('.videoTestimonialModal').length;
    $('a[data-role="videoChange"]').on('click', function () {
            console.log($(this).data('action'));
            switch ($(this).data('action')) {
                case 'prev':
                    if (actual === 0) {
                        switchVideo(actual, total - 1);
                    }
                    switchVideo(actual, --actual);
                    break;
                case 'next':
                    if (actual === total - 1) {
                        switchVideo(actual, 0);
                    }
                    switchVideo(actual, ++actual);
                    break;
                default:
                    break;
            }
        }
    );
    $('.videoTestimonialModal').on('shown.bs.modal', function () {
        actual = $(this).data('index');
    });


    $('a[href^="mailto:"]').each(function () {
        this.href = this.href.replace('(at)', '@').replace(/\(dot\)/g, '.');
        // Remove this line if you don't want to set the email address as link text:
        this.innerHTML = this.href.replace('mailto:', '');
    });

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages
                function mainMenuAdjust() {
                    if ($(window).width() >= 320 /*975*/) {
                        if (!$('#menu-main-menu.nav>li:nth-child(4)')
                                .hasClass('menu-item-logo')) {
                            $('#menu-main-menu.nav>li:nth-child(3)')
                                .after('<li class="menu-item-logo"></li>');
                            $('a.brand').appendTo($('#menu-main-menu.nav>li:nth-child(4)'));
                        }
                    } else {
                        if ($('#menu-main-menu.nav>li:nth-child(4)')
                                .hasClass('menu-item-logo')) {
                            $('#menu-main-menu.nav>li:nth-child(4) a.brand')
                                .prependTo($('.main-logo-container'));
                            $('#menu-main-menu.nav>li:nth-child(4)').remove();

                        }

                    }
                }

                $('#menu-main-menu.nav')
                    .prepend('<li class="menu-item menu-item-top-message"></li>');
                $('.mobile-menu-top-message')
                    .removeClass('hide')
                    .prependTo('header .menu-item-top-message');

                $(window).resize(mainMenuAdjust);
                mainMenuAdjust();

                // Adding Mobile first item
                $('.menu-item-top-message').next()
                    .addClass('first-menu-item');
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
               
                $("#home-banner").responsiveSlides({
                    auto: true,
                    pager: false,
                    speed: 500,
                    timeout: 5000
                  });

            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
                console.log('about us');
            }
        },
        'our_services':{
            init: function(){
                    $('.tabcontent').find('a.modal-video').click(function(e){
                        e.preventDefault();
                        id = $(this).data('target');

                        video = 'https://www.youtube.com/embed/' + id;

                        $('#modal-tour-video').toggleClass('in').show().find('iframe').attr('src', video);
                        $('body').append( '<div class="modal-backdrop in"></div>');

                    });

                     $('#modal-tour-video').on('click', 'button', function(){
                        $('#modal-tour-video').toggleClass('in').hide();
                        $('.modal-backdrop').remove();
                     });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');


        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);
    $(function () {
        var modalSelect = '.yt-modal';
        $(modalSelect).on('show.bs.modal', function (e) {
            var jthis = $(this),
                body = jthis.find('.modal-body'),
                id = jthis.attr('ytv-id');
            if (!jthis.data('yt-player')) {
                var player = new YT.Player('ytv-' + id, {
                    height: '315',
                    width: '560',
                    videoId: id
                });
                $.data(this, 'yt-player', player);
            }
        });
        modalSelect += ',#modalMainVideo,#aboutVideo';
        $(modalSelect).on('hidden.bs.modal', function (e) {
            $(this).find('iframe').attr('src', $(this).find('iframe').attr('src'));
        });
    });

})(jQuery, window);// Fully reference jQuery after this point.
